import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React from 'react';

import Home from './components/Home';




export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        
        <Route exact path="*" element={<Navigate to={"/"} />} />
        
      </Routes>
    </BrowserRouter>
  );
}


