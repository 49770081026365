import React from 'react';



function Footer() {
  return (
    <>
    
    
    <footer className="footer-area">
  <div className="footer-top">
    <div className="newsletter">
      <h4>SignUp Our Newsletter</h4>
      <form className="mailchimp inputSubscribeDiv">
        <input type="email" name="subscribe" id="subscriber-email" placeholder="Your Mail" className="form-control" />
        <button type="submit" id="subscribe-button"><i className="fa fa-paper-plane" /></button>
        <div className="subscription-success"> </div>
        <div className="subscription-error"> </div>
        <label className="subscription-label" htmlFor="subscriber-email" />
      </form>
    </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-lg-4 col-sm-6">
        <div className="footer-widget footer-about">
          <div className="f-logo"><a href="#">
              <img src="images/components/f-logo.png" alt="Logo" />
            </a></div>
          <p>Help us to save lifes during a natural disaster, get clean water running in the most remote
            areas, and much more.</p>
          <div className="footer-social">
            <a href="#"><i className="fa-brands fa-facebook-f" /></a>
            <a href="#"><i className="fa-brands fa-twitter" /></a>
            <a href="#"><i className="fa-brands fa-youtube" /></a>
            <a href="#"><i className="fa-brands fa-pinterest" /></a>
            <a href="#"><i className="fa-brands fa-instagram" /></a>
          </div>
        </div>
      </div>
      <div className="col-lg-2 offset-lg-1 col-sm-5 offset-sm-1">
        <div className="footer-widget">
          <h3 className="widget-title">about us</h3>
          <ul>
            <li><a href="#">About Employee</a></li>
            <li><a href="#">About Carrier</a></li>
            <li><a href="#">How it works</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </div>
      </div>
      <div className="col-lg-2 col-sm-6">
        <div className="footer-widget">
          <h3 className="widget-title">Useful Links</h3>
          <ul>
            <li><a href="#">Project</a></li>
            <li><a href="#">Donner</a></li>
            <li><a href="#">Relief</a></li>
            <li><a href="#">Sanitaion</a></li>
            <li><a href="#">News</a></li>
          </ul>
        </div>
      </div>
      <div className="col-lg-3 col-sm-5 offset-sm-1 offset-lg-0">
        <div className="footer-widget">
          <h3 className="widget-title">Contact Us</h3>
          <ul className="footer-contact">
            <li><i className="fa fa-home" /> <span>129, London Street, South London UK.</span></li>
            <li><i className="fa fa-phone" /><span><a href="tel:+6494461709.7890">+00- 123.456.7890</a>
                <br /><a href="tel:+6494461709.7890">+00- 223.456.7890</a></span></li>
            <li><i className="fa fa-envelope" /> <span><a href="https://html.theme-village.com/cdn-cgi/l/email-protection#630b060f1323040e020a0f4d000c0e"><span className="__cf_email__" data-cfemail="88e0ede4f8c8efe5e9e1e4a6ebe7e5">[email&nbsp;protected]</span></a></span></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="row copy-right g-0">
      <div className="col-xl-6 col-md-7 order-2 order-md-1">
        <p>Copyright © 2024. All Rights Reserved by <a href="#">theme-village</a></p>
      </div>
      <div className="col-xl-6 col-md-5 order-1 order-md-2">
        <nav className="quick-link text-lg-end">
          <ul>
            <li><a href="#" className="nav-link">Terms &amp; Conditions</a></li>
            <li><a href="#" className="nav-link">Privacy Policy</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</footer>







    </>
  );
}

export default Footer;