import React from 'react';
import Header from './Header';
import Footer from './Footer';




function Home() {

    
  return (
    <>
    
  <Header/>  

 <div>
 <section className="p-0 top-position1">
  <div className="slider-fade owl-carousel owl-theme w-100">
    <div className="text-start item bg-img cover-background pt-6 pb-14 py-md-16 py-lg-20 py-xxl-24 rounded-lg left-overlay-dark" data-overlay-dark={85} data-background="assets/img/banner/slide-01.jpg">
      <div className="container pt-6 pt-md-0">
        <div className="row align-items-center">
          <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-5">
            <span className="text-primary mb-3 d-block fw-bold display-md-28">Expertly crafted policies</span>
            <h1 className="text-white display-21 display-sm-19 display-md-17 display-lg-8 mb-4">Dependable assurance for the entire family</h1>
            <p className="text-white mb-2-3 opacity8 display-md-28 w-lg-80">We are driving protection giving organization everywhere on the world doing more than 25 years.</p>
            <a href="#!" className="butn-style1 fill me-2 my-1 my-sm-0">Make a Claim</a>
            <a href="#!" className="butn-style1 my-1 my-sm-0">Find An Agent</a>
          </div>
        </div>
      </div>
    </div>
    <div className="text-start item bg-img cover-background pt-6 pb-14 py-md-16 py-lg-20 py-xxl-24 rounded-lg left-overlay-dark" data-overlay-dark={85} data-background="assets/img/banner/slide-02.jpg" >
      <div className="container pt-6 pt-md-0">
        <div className="row align-items-center">
          <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-5">
            <span className="text-primary mb-3 d-block fw-bold display-md-28">Auto assurance</span>
            <h1 className="text-white display-21 display-sm-19 display-md-17 display-lg-8 mb-4">Caring car for better journey assurance</h1>
            <p className="text-white mb-2-3 opacity8 display-md-28 w-lg-80">We are driving protection giving organization everywhere on the world doing more than 25 years.</p>
            <a href="#!" className="butn-style1 fill me-2 my-1 my-sm-0">Make a Claim</a>
            <a href="#!" className="butn-style1 my-1 my-sm-0">Find An Agent</a>
          </div>
        </div>
      </div>
    </div>
    <div className="text-start item bg-img cover-background pt-6 pb-14 py-md-16 py-lg-20 py-xxl-24 rounded-lg left-overlay-dark" data-overlay-dark={85} data-background="assets/img/banner/slide-03.jpg">
      <div className="container pt-6 pt-md-0">
        <div className="row align-items-center">
          <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mb-1-9 mb-lg-0 py-5">
            <span className="text-primary mb-3 d-block fw-bold display-md-28">Property insurance</span>
            <h1 className="text-white display-21 display-sm-19 display-md-17 display-lg-8 mb-4">A moments of caring your dream home</h1>
            <p className="text-white mb-2-3 opacity8 display-md-28 w-lg-80">We are driving protection giving organization everywhere on the world doing more than 25 years.</p>
            <a href="#!" className="butn-style1 fill me-2 my-1 my-sm-0">Make a Claim</a>
            <a href="#!" className="butn-style1 my-1 my-sm-0">Find An Agent</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

 </div>



<Footer/>
    </>
  );
}

export default Home;