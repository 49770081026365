import React from 'react';



function Header() {
  return (
    <>
    
    <div id="preloader"></div>
  
    <div className="main-wrapper">
  <header className="header-style1 menu_area-light">
    <div className="navbar-default border-bottom border-color-light-white">
      {/* start top search */}
      <div className="top-search bg-secondary">
        <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
          <form className="search-form" action="https://secure.websitelayout.net/search.html" method="GET" acceptCharset="utf-8">
            <div className="input-group">
              <span className="input-group-addon cursor-pointer">
                <button className="search-form_submit fas fa-search text-white" type="submit" />
              </span>
              <input type="text" className="search-form_input form-control" name="s" autoComplete="off" placeholder="Type & hit enter..." />
              <span className="input-group-addon close-search mt-1"><i className="fas fa-times" /></span>
            </div>
          </form>
        </div>
      </div>
      {/* end top search */}
      <div className="container-fluid px-lg-1-6 px-xl-2-5 px-xxl-2-9">
        <div className="row align-items-center">
          <div className="col-12 col-lg-12">
            <div className="menu_area alt-font">
              <nav className="navbar navbar-expand-lg navbar-light p-0">
                <div className="navbar-header navbar-header-custom">
                  {/* start logo */}
                  <a href="index.html" className="navbar-brand"><img id="logo" src="assets/img/logos/logo-inner.png" alt="logo" /></a>
                  {/* end logo */}
                </div>
                <div className="navbar-toggler" />
                {/* start menu area */}
                <ul className="navbar-nav ms-auto" id="nav" style={{display: 'none'}}>
                  <li><a href="#!">Home</a>
                    <ul>
                      <li><a href="index-1.html">Home Page 1</a></li>
                      <li><a href="index-2.html">Home Page 2</a></li>
                      <li><a href="index-3.html" target="_blank">Home Page 3</a></li>
                    </ul>
                  </li>
                  <li>
                    <a href="#!">Pages</a>
                    <ul>
                      <li><a href="about.html">About Us</a></li>
                      <li><a href="team.html">Our Team</a></li>
                      <li><a href="testimonials.html">Testimonials</a></li>
                      <li><a href="pricing.html">Pricing</a></li>
                      <li><a href="contact.html">Contact</a></li>
                      <li><a href="faq%27s.html">Faq's</a></li>
                      <li><a href="comingsoon.html">Comingsoon</a></li>
                      <li><a href="404.html">404</a></li>
                    </ul>
                  </li>
                  <li><a href="#!">Services</a>
                    <ul>
                      <li><a href="services-1.html">Services 1</a></li>
                      <li><a href="services-2.html">Services 2</a></li>
                      <li><a href="services-3.html">Services 3</a></li>
                      <li><a href="service-details.html">Services Details</a></li>
                    </ul>
                  </li>
                  <li><a href="#!">Portfolio</a>
                    <ul>
                      <li><a href="portfolio.html">Portfolio</a></li>
                      <li><a href="portfolio-details.html">Portfolio Details</a></li>
                    </ul>
                  </li>
                  <li><a href="#!">Elements</a>
                    <ul className="row megamenu">
                      <li className="col-lg-3">
                        <span className="mb-0 mb-lg-3 d-block py-2 p-lg-0 px-4 px-lg-0 text-uppercase sub-title font-weight-600 display-30">Elements 01</span>
                        <ul>
                          <li><a href="accordion.html"><i className="fas fa-sliders-h me-2" />Accordions</a></li>
                          <li><a href="alerts.html"><i className="far fa-bell me-2" />Alerts</a></li>
                          <li><a href="blockquotes.html"><i className="fas fa-vector-square me-2" />Blockquote</a></li>
                          <li><a href="buttons.html"><i className="fas fa-link me-2" />Buttons</a></li>
                          <li><a href="call-to-action.html"><i className="far fa-square me-2" />Call to Action</a></li>
                          <li><a href="carousel-slider.html"><i className="far fa-images me-2" />Carousel Slider</a></li>
                          <li><a href="count-down.html"><i className="far fa-flag me-2" />Count Down</a></li>
                        </ul>
                      </li>
                      <li className="col-lg-3">
                        <span className="mb-0 mb-lg-3 d-block py-2 p-lg-0 px-4 px-lg-0 text-uppercase sub-title font-weight-600 display-30">Elements 02</span>
                        <ul>
                          <li><a href="counter.html"><i className="fas fa-compress me-2" />Counters</a></li>
                          <li><a href="dropcaps.html"><i className="far fa-closed-captioning me-2" />Dropcaps</a></li>
                          <li><a href="forms.html"><i className="fab fa-wpforms me-2" />Forms</a></li>
                          <li><a href="form-elements.html"><i className="fas fa-cubes me-2" />Form Elements</a></li>
                          <li><a href="font-icons.html"><i className="far fa-check-square me-2" />Font Icons</a></li>
                          <li><a href="google-map.html"><i className="fas fa-map-marker-alt me-2" />Google Map</a></li>
                          <li><a href="grid-system.html"><i className="fas fa-th me-2" />Grid System</a></li>
                        </ul>
                      </li>
                      <li className="col-lg-3">
                        <span className="mb-0 mb-lg-3 d-block py-2 p-lg-0 px-4 px-lg-0 text-uppercase sub-title font-weight-600 display-30">Elements 03</span>
                        <ul>
                          <li><a href="highlights.html"><i className="fas fa-highlighter me-2" />Highlights</a></li>
                          <li><a href="icon-with-text.html"><i className="fab fa-fonticons-fi me-2" />Icon With Text</a></li>
                          <li><a href="list-styles.html"><i className="fas fa-list-ul me-2" />List Styles</a></li>
                          <li><a href="media-object.html"><i className="fas fa-photo-video me-2" />Media Object</a></li>
                          <li><a href="modal.html"><i className="fas fa-expand me-2" />Modal</a></li>
                          <li><a href="pagination.html"><i className="far fa-caret-square-right me-2" />Pagination</a></li>
                          <li><a href="progress-bar.html"><i className="fas fa-tasks me-2" />Progress Bars</a></li>
                        </ul>
                      </li>
                      <li className="col-lg-3">
                        <span className="mb-0 mb-lg-3 d-block py-2 p-lg-0 px-4 px-lg-0 text-uppercase sub-title font-weight-600 display-30">Elements 04</span>
                        <ul>
                          <li><a href="pagetitle1.html"><i className="fas fa-compress me-2" />Page Title1</a></li>
                          <li><a href="pagetitle2.html"><i className="fas fa-compress-arrows-alt me-2" />Page Title2</a></li>
                          <li><a href="social-icons.html"><i className="fas fa-bezier-curve me-2" />Social Icons</a></li>
                          <li><a href="tables.html"><i className="fas fa-table me-2" />Tables</a></li>
                          <li><a href="tabs.html"><i className="fas fa-sliders-h me-2" />Tabs</a></li>
                          <li><a href="typography.html"><i className="fas fa-text-height me-2" />Typography</a></li>
                          <li><a href="video.html"><i className="fas fa-video me-2" />Videos</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#!">Blog</a>
                    <ul>
                      <li><a href="blog-grid.html">Blog Grid</a></li>
                      <li><a href="blog-list.html">Blog List</a></li>
                      <li><a href="blog-details.html">Blog Details</a></li>
                    </ul>
                  </li>
                </ul>
                {/* end menu area */}
                {/* start attribute navigation */}
                <div className="attr-nav align-items-lg-center ms-lg-auto main-font">
                  <ul>
                    <li className="search"><a href="#!"><i className="fas fa-search" /></a></li>
                    <li className="d-none d-lg-inline-block"><a href="#!" className="butn-style1 fill medium text-white">Free Quote</a></li>
                  </ul>
                </div>
                {/* end attribute navigation */}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>

    
    </>
  );
}

export default Header;